import Link from 'next/link';

import { TLink } from '../../resources/links';
export const InternalLink = ({ link, name, soon }: TLink) => {
  return (
    <Link
      href={link ?? ''}
      key={name}
      prefetch={false}
      passHref
      className="pb-8 font-medium text-display-0 paragraph-14"
    >
      {name}{' '}
      {soon && (
        <span className="font-medium text-display-0 paragraph-14">
          (em breve)
        </span>
      )}
    </Link>
  );
};

export const ExternalLink = ({ link, name }: TLink) => {
  return (
    <a
      className="pb-8 font-medium text-display-0 paragraph-14"
      href={link}
      key={name}
      target="_blank"
      rel="noreferrer"
    >
      {name}
    </a>
  );
};

export const NoLink = ({ name, className }: TLink) => {
  return (
    <div
      key={name}
      className={`${className} mx-6 cursor-pointer border-none bg-none text-left text-sm font-medium text-display-0 outline-inherit`}
    >
      {name}
    </div>
  );
};

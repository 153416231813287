import classNames from 'classnames';
import Link from 'next/link';

import { productsItems } from '../../resources/products';
import styles from './Products.module.css';

const Products: React.FC = () => (
  <div className={styles.container}>
    {productsItems.map(({ name, Logo, active, url }) => (
      <Link
        key={name}
        href={url}
        prefetch={false}
        className={classNames(`${styles.product}`, {
          [styles.productActive]: active,
        })}
      >
        <div className="w-48 h-24">
          <Logo />
        </div>
        <div className="flex overflow-hidden items-end max-h-0">
          <p className="mt-10 text-[10px] text-center text-display-0">{name}</p>
        </div>
      </Link>
    ))}
  </div>
);

export default Products;

'use client';

import { useResponsiveQuery } from 'atomic-layout';
import { usePathname } from 'next/navigation';

import { personalizationIsNoMenu } from '@/utils/personalization';

import Disclaimer from './components/Disclaimer';
import Links from './components/Links';
import LogoAndSocialMedias from './components/LogoAndSocialMedias';
import Products from './components/Products';
import Stoneco from './components/Stoneco';
import { linkItems, linkItemsNoMenu, linksMasterchef } from './resources/links';

const Footer: React.FC = () => {
  const pathname = usePathname() || '';
  const isNoMenu = personalizationIsNoMenu(pathname);
  const isMobile = useResponsiveQuery({ to: 'md' });
  const masterchefNew = pathname.includes('/masterchefbr');
  const links = masterchefNew ? linksMasterchef : linkItems;

  return (
    <>
      <div
        id="Context_footer"
        className="py-[32px] px-[24px] text-display-0 bg-display-900"
      >
        <div className="my-0 mx-auto max-w-[1324px]">
          <LogoAndSocialMedias isNoMenu={isNoMenu} />

          {isMobile ? (
            <Links linkItems={links} />
          ) : (
            <Links linkItems={isNoMenu ? linkItemsNoMenu : links} />
          )}

          <Disclaimer />

          <Stoneco />

          {!isNoMenu && <Products />}
        </div>
      </div>
    </>
  );
};

export default Footer;

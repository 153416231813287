import PagarmeLogo from '@/assets/icons/otherProducts/pagarme.svg';
import TonLogo from '@/assets/icons/otherProducts/ton.svg';
import StoneLogo from '@/assets/icons/stoneLogo/potencia.svg';

export type TProduct = {
  name: string;
  Logo: '*.svg';
  active?: boolean;
  url: string;
};

export const productsItems: TProduct[] = [
  {
    name: 'STONE',
    Logo: StoneLogo,
    active: true,
    url: '/',
  },
  {
    name: 'TON',
    Logo: TonLogo,
    url: 'https://www.ton.com.br/',
  },
  {
    name: 'PAGAR.ME',
    Logo: PagarmeLogo,
    url: 'https://www.pagar.me/',
  },
];

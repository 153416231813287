import Icon from '@/app/components/base/ui/Icon';

const Stoneco: React.FC = () => {
  return (
    <div className="flex justify-end mb-[24px] h-full">
      <a href="https://investors.stone.co/" aria-label="Stone investidor link">
        <Icon id="StonecoLogo" size={16} />
      </a>
    </div>
  );
};

export default Stoneco;
